import React from 'react';
import upworkImg from "../assets/images/upwork.svg";
import linkedinImg from "../assets/images/linkedin.svg";
import contraImg from "../assets/images/contra.svg";
import getCompletedURL from "src/utils/getCompletedURL";
import gradientImg from "../assets/icons/gradient.png";
const TeamMemberCard = ({ data, index }) => {
    return (
        <div className="wrapper">
            <div className='mb-4 about-title' style={index%2===0?{left:0}:{right:0}}>
                <img src={gradientImg} alt="" height={10} />
                <p className='fs-b color-white text-start '>{data.role}</p>
            </div>
            <div className="avatar">
                <img src={getCompletedURL(data.imgUrl)} alt="member-avatar" className="team-member-image" style={{ maxWidth: '500px' }} />

            </div>
            <h3 className="team-member-name fs-28">{data.name}</h3>
            <p className="team-member-role fs-c">{data.detail}</p>
            <div className="social-links">
                <a href={data.linkedin} target="_blank" rel="noopener noreferrer">
                    <img src={linkedinImg} alt="Linkedin" />
                </a>
                <a href={data.upwork} target="_blank" rel="noopener noreferrer">
                    <img src={upworkImg} alt="Upwork" />
                </a>
                <a href={data.contra} target="_blank" rel="noopener noreferrer">
                    <img src={contraImg} alt="Contra" />
                </a>
            </div>
        </div>
    );
};

export default TeamMemberCard;