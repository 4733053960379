import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import StartUp from "../HomePage/startup";
import CommonBtn from "src/components/button/CommonBtn";
import { addLineBreak } from "src/libs/validate";
import { getProject } from "src/services/project/project";
import getCompletedURL from "src/utils/getCompletedURL";
import leftImg from "../../assets/images/left-side-effect.svg";
import "../../styles/pages/projectdetail.scss";
import ActiveBtn from "src/components/button/ActiveBtn";
import teamIcon from "../../assets/icons/team.svg";
import durationIcon from "../../assets/icons/duration.svg";
import stackIcon from "../../assets/icons/stack.svg";

const ProjectDetail = () => {
    const { id } = useParams();
    const [data, setData] = useState();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        scrollToTop();
        getProject(id).then(res => {
            setData(res.result.data)
        })
    }, [id]);

    return data && <div className="project-detail-section">
        <div className="title-section">
            <div className="detail-home container mt-160 mb-5">
                <div className="d-flex justify-content-between">
                    <CommonBtn title="Back to portfolio" className={"color-white m-2"} onClick={() => {
                        window.location.href = data.link
                    }} />
                    <div className="color-white fs-h1">{data.title}</div>
                    <ActiveBtn title="Visite website" className={"color-black m-2"} onClick={() => {
                        window.location.href = data.link
                    }} />
                </div>
                <div className="detail-home-img"><img src={getCompletedURL(data.img)} alt="main" /></div>
            </div>
        </div>
        <div className="content-section container mt-5">
            <img src={leftImg} alt="left side" className="left-side-effect" />
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 p-3">
                    <div className="color-gradi fs-h5 pt-2 pb-2 border-gradi">Services</div>
                    <div className="color-white fs-h5 mt-5">{data.services}</div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 p-3">
                    <div className="color-gradi fs-h5 pt-2 pb-2 border-gradi">Industry</div>
                    <div className="color-white fs-h5 mt-5">{data.industry}</div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 p-3">
                    <div className="color-gradi fs-h5 pt-2 pb-2 border-gradi">Platform</div>
                    <div className="color-white fs-h5 mt-5">{data.platform}</div>
                </div>
            </div>

            <div className="row mtb-50">
                <div className="text-left col-lg-4 col-md-12 col-sm-12 color-white  p-3 ">
                    <p className="gray-div fs-s0">About client</p>
                </div>
                <p className="col-lg-8 col-md-12 col-sm-12 color-white fs-b p-3 lh-base">
                    {addLineBreak(data.client)}
                </p>
            </div>
            <div className="row mtb-50">
                <div className=" text-left col-lg-4 col-md-12 col-sm-12 color-white p-3">
                    <p className="gray-div fs-s0 ">Task Overview</p>

                </div>
                <p className="col-lg-8 col-md-12 col-sm-12 color-white fs-b p-3 lh-base">
                    {addLineBreak(data.overview)} <br></br><br></br><br></br>
                </p>
            </div>
            <div className="row mt-5">
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="stack-card">
                        <div className="d-flex justify-content-center align-items-center  mb-5" style={{ gap: 5 }}>
                            <img src={teamIcon} alt="" width={48} height={48} />
                            <div className="color-gradi fs-s1 text-center">Team</div>
                        </div>
                        {data.team.map((item, index) => (
                            <div className="color-white fs-b text-center my-1" key={index}>{item}</div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="stack-card">
                        <div className="d-flex justify-content-center align-items-center mb-5" style={{ gap: 5 }}>
                            <img src={durationIcon} alt="" width={48} height={48} />
                            <div className="color-gradi fs-s1 text-center mt-2 ">Duration</div>
                        </div>
                        <div className="color-white text-center fs-b my-1">{data.duration}</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="stack-card">
                        <div className="d-flex justify-content-center align-items-center  mb-5" style={{ gap: 5 }}>
                            <img src={stackIcon} alt="" width={48} height={48} />
                            <div className="color-gradi fs-s1 text-center">Technology stack</div>
                        </div>
                        <div className="my-1 text-center">
                            {data.stacks.map((item, index) => (
                                <span key={index} className="color-white text-center fs-b my-1">{item}, &nbsp;</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mtb-50">
                <div className="col-lg-4 col-md-12 col-sm-12 p-3 text-left">
                    <p className="gray-div fs-s0 color-black" style={{ background: 'linear-gradient(90deg, #71EA9F 0%, #A16FF6 100%)' }}>
                        Results
                    </p>
                </div>
                <div
                    className="col-lg-8 col-md-12 col-sm-12 color-white fs-b p-3 lh-base">{addLineBreak(data.result)}
                </div>
            </div>
        </div>
        <StartUp />
    </div>
}

export default ProjectDetail;