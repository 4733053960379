import React, { useState, useCallback } from 'react';
import { stepData } from 'src/config/stepData';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import rightArrow from "../../assets/images/right-arrow.svg";
import leftImg from "../../assets/images/about-left-effect.svg";

const HowWeWork = () => {
  const [active, setActive] = useState(0); // Initial value for the slider
  const handleActive = useCallback(
    (index) => () => setActive((prev) => ((prev + index) % 4)),
    [],
  )
  return <div className='about-how-section'>
    <div className='container'>
      <div className='fs-h2 color-white p-1 title'>How we work</div>
      <div className="step-section mt-5 mtb-100">
        <img src={leftImg} alt="left side" className="left-side-effect" />
        <Tabs selectedIndex={active}>
          <TabList className="tab-steps">
            {
              stepData.map((item, index) => (
                <Tab key={index} className={`tab-step fs-s2 color-white text-left ${active === index ? "active" : ""}`}
                  onClick={() => setActive(index)}>
                  {item.stepNumber}
                </Tab>
              ))
            }
          </TabList>
          {
            stepData.map((item, index) => (
              <TabPanel key={index}>
                <div className="step-card color-white text-start fs-24">
                  {item.description}
                </div>
              </TabPanel>
            ))
          }
        </Tabs>
        <div className="d-flex justify-content-end align-items-end px-3" style={{ position: 'absolute' }}>
          {active !== 0 && <p className='px-3 my-3 color-gray' style={{ cursor: 'pointer' }} onClick={handleActive(-1)} >Back</p>}
          {(active === 2 || active === 1) && <hr className='m-3 color-gray' />}
          {active !== 3 && <p className='px-3 my-3 color-gray' style={{ cursor: 'pointer' }} onClick={handleActive(1)}>Next step</p>}
          <img src={rightArrow} className='mx-3 my-3' alt="right arrow" width={26} style={{ cursor: 'pointer' }} onClick={handleActive(1)} />
        </div>
      </div>
    </div>
  </div>

}

export default HowWeWork;