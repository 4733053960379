import React from 'react';
import ActiveBtn from "../../components/button/ActiveBtn";
import CommonBtn from 'src/components/button/CommonBtn';
import { useNavigate } from "react-router-dom";

import solarImg from "../../assets/logos/solar.png";
import serenityImg from "../../assets/logos/serenity.png";
import gravitonImg from "../../assets/logos/graviton.png";
import slashImg from "../../assets/logos/slash.png";
const Home = () => {
    const navigate = useNavigate();
    return <div className='home-section' id='home'>
        <div className='container'>
            <div className='fs-b color-gray4 p-1 text-center'>We redefine possibilities through cutting-edge technology</div>
            <div className='fs-h1 color-primary home-title p-1'>Unlocking Innovation In Technology</div>
            <div className='fs-s1 color-white home-detail p-4 lh-base'>Our passion lies in creating innovative solutions that transform businesses, inspire change, and push the boundaries of what's achievable. </div>
            <div className='home-buttons'>
                <ActiveBtn title="Talk to Us"className={"m-3"} onClick={() => { navigate("/talk-to-us") }} />
                <CommonBtn title={"Explore Services"} className={"color-white m-3"} onClick={() => { navigate("/services") }} />
            </div>

        </div>
        <div className='home-footer'>
            <div className='fs-s1 color-white m-3'>Trusted by:</div>
            <div className='d-flex'>
                <div className='trust-item'><img src={solarImg} alt="solar logo" /></div>
                <div className='trust-item'><img src={serenityImg} alt="serenity logo"/></div>
                <div className='trust-item' style={{padding:'3px 25px'}}><img src={gravitonImg} alt="graviton logo" /></div>
                <div className='trust-item'><img src={slashImg} alt="slash logo" /></div>
            </div>
        </div>
    </div>

}

export default Home;