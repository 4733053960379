import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import "../../styles/pages/servicedetail.scss";
import Industry from "../HomePage/industry";
import StartUp from "../HomePage/startup";
import { getService } from "src/services/service/service";
import getCompletedURL from "src/utils/getCompletedURL";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import aboutImg from "../../assets/images/about.svg";
const ServiceDetail = () => {
    const [active, setActive] = useState(0);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        scrollToTop();
        getService(id).then(res => {
            setData(res.result);
            setCategory(res.result.category);
        })
    }, [id]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return <div className="detail-section">
        <div className="container detail-container text-center">
            <img src={getCompletedURL(data.icon)} alt="" width={64} height={64} />
            <div className="color-gradi fs-h1">{data.subtitle}</div>
            <div className="color-white fs-18 service-content mb-5"> {data.detail}</div>
            <div className="detail">
                <img src={aboutImg} alt='about' className="about-image"/>
                <div className="color-white fs-28 mt-3">
                    {data.content}
                </div>
            </div>
            <div className="color-white fs-h1 technical-title">
                Technology stack
            </div>
            <div className="category">
                <Tabs className="tab-container">
                    <TabList>
                        {
                            category && category.map((item, index) => (
                                <Tab key={index}
                                    className={`sub-category fs-s1 color-white m-4 ${active === index ? "active" : ""}`}
                                    onClick={() => setActive(index)}>
                                    {active === index ? "-" : ""} {item.title}
                                </Tab>
                            ))
                        }
                    </TabList>
                    <div style={{ width: "100%" }}>
                        {
                            category && category.map((item, index) => (
                                <TabPanel key={index} className="">
                                    <div className="d-flex justify-content-between">
                                        <div className="tech-detail color-white fs-b1 m-4 text-start ">{item.detail}</div>
                                        <div className="teclogo-container row m-4">
                                            {item.icons.map((el, index) => (
                                                <div key={index} className='col-6'>
                                                    <div className="logo-detail">
                                                        <img src={getCompletedURL(el.icon)} alt="tech-icon" />
                                                        <div className="fs-b2 color-white mt-2">{el.title}</div>
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </div>

                                    </div>
                                </TabPanel>
                            ))
                        }
                    </div>
                </Tabs>
            </div>
        </div>
        <Industry />
        <div className="mt-5">
            <StartUp />
        </div>
    </div>
}

export default ServiceDetail;