import React from 'react';
import cx from "classnames";
import rightArrow from "../assets/images/right-arrow.svg"
import { Link } from "react-router-dom";

import getCompletedURL from "src/utils/getCompletedURL";

const ServiceCard = ({ data, className, index, landing }) => {
    let classNames = cx('service-card', className);
    return <div className={classNames}>
        <div className="d-flex">
            <div className="d-flex flex-column justify-content-center align-items-start" style={{ padding: '30px' }}>
                <div className="service-logo">
                    <img src={getCompletedURL(data.icon)} alt="" />
                </div>
                <p className="color-white fs-32-b my-3">
                    {data.subtitle}
                </p>
                <p className="color-white fs-b my-3">
                    {data.intro}
                </p>
                <Link to={`/service/${data._id}`} style={{display:'inline-flex'}} className='my-2'>
                    <p className='fs-b color-gray' style={{margin:'auto'}}>Detail</p>
                    <img src={rightArrow} className='mx-3' alt="right arrow" style={{ width: "26px" }} />
                </Link>
            </div>
            <div className="service-image">
                <img src={getCompletedURL(data.image)} alt="" />
            </div>

        </div>
    </div>
}

export default ServiceCard;