import React from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import CommonBtn from 'src/components/button/CommonBtn';
import ProjectCard from 'src/components/projectCard';
import {allProjects} from "src/features/project/projectSlice";

const Project = () => {
    const navigate = useNavigate();
    const allProjectData = useSelector(allProjects);

    return <div className='project-section' id='project'>
        <div className='container'>
            <div className='d-flex'>
                <div className='project-detail'>
                    <div className='fs-h2 color-white my-4 text-start'>Projects</div>
                    <div className='fs-18 color-white my-4 text-start'>
                        Explore our diverse portfolio, showcasing a range of projects that demonstrate our innovative solutions and technical prowess.
                    </div>
                </div>
                <div className='project-all'>
                    <CommonBtn title={"See all"} className={"color-white"} onClick={() => {
                        navigate("/projects")
                    }}/>
                </div>
            </div>
            <div className='project-container row'>
                {allProjectData && allProjectData.slice(0, 4).map((item, index) => (
                        <div className='card-row' key={index} onClick={() => {
                            navigate(`/project/${item._id}`)
                        }}>
                            <ProjectCard data={item}/>
                        </div>
                    )
                )}
            </div>
        </div>
    </div>
}

export default Project;