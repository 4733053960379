import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import ServiceCard from 'src/components/ServiceCard';

import { useSelector } from "react-redux";
import { allServices } from "src/features/service/serviceSlice";


const Services = () => {
    const navigate = useNavigate();
    const allServiceData = useSelector(allServices);
   
    return <div className='service-section' id='service'>
        <div className="container">
            <div className='service-title color-white fs-c'>Our Services </div>
            <Swiper pagination={{ dynamicBullets: true, }} modules={[Pagination]}>
                {
                    allServiceData && allServiceData.slice(0, 4).map((item, index) => (
                        <SwiperSlide>
                            <div className='' key={index} onClick={() => {
                                navigate(`/service/${item._id}`)
                            }}>
                                <ServiceCard data={item} landing={true} key={index} index={index} />
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
        
    </div>
}

export default Services;