import React from 'react';
import CommonBtn from 'src/components/button/CommonBtn';
import aboutImg from "../../assets/images/about.svg";
import { useNavigate } from "react-router-dom";
import gradientImg from "../../assets/icons/gradient.png";
const About = () => {
    const navigate = useNavigate();
    return <div className='about-section' id='about'>
        <div className='container row'>
            <div className='about-us d-flex justify-content-center align-items-center'>
                <div className='col-lg-5 col-sm-12 col-md-12 about-img'>
                    <img src={aboutImg} alt='about' />
                </div>
                <div className='about-content col-lg-7 col-sm-12 col-md-12'>
                    <div className='mb-4 about-title'>
                        <img src={gradientImg} alt="" height={10} />
                        <p className='fs-b color-white text-start '>About Us</p>
                    </div>
                    <div className='color-white text-start lh-base' style={{ fontSize: 28 }}>
                        With expertise in crafting comprehensive software solutions from inception to implementation, we are proficient in various programming languages and frameworks.
                    </div>
                    <p className='my-2 fs-b' style={{ color: '#7B7F94' }}>
                        Our talented developers and managers, who passed a 3-stage vetting process and worked together on a number of projects, will make sure that the final deliverable fully satisfies your expectations.
                    </p>
                    <div className='my-4'>
                        <CommonBtn title={"Read more"} className={"color-white"} onClick={() => { navigate("/about-us") }} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default About;