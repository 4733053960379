import expertise from "../assets/icons/expertise.svg";
import communication from "../assets/icons/communication.svg";
import innovation from "../assets/icons/innovation.svg";
import timely from "../assets/icons/timely.svg";
import customization from "../assets/icons/customization.svg";
import quality from "../assets/icons/quality.svg";

export const whyData = [
    {
        logo: expertise,
        title: "Expertise",
        detail: "We bring years of industry experience, ensuring every project is handled with the utmost knowledge and skill."
    },
    {
        logo: communication,
        title:"Communication",
        detail: "Transparent and open communication with our clients is at the heart of our process, ensuring you are informed every step of the way."
    },
    {
        logo: innovation,
        title: "Innovation",
        detail: "We embrace fresh ideas and technologies to provide creative and modern solutions tailored to your needs."
    },
    {
        logo: timely,
        title: "Timely Delivery",
        detail: "We adhere to deadlines and prioritize efficiency to deliver your projects on time without compromising quality."
    },
    {
        logo: customization,
        title: "Customization",
        detail: "Our approach is tailored to your unique requirements, guaranteeing a personalized solution for every project."
    },
    {
        logo:quality,
        title:"Quality Assurance",
        detail: "We ensure the highest standards are met at every stage, delivering a product you can rely on with full confidence."
    }
]