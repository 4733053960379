// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/service-bg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/service-bg-md.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/service-bg-sm.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-list {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: top;
}
.project-list .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
}
.project-list .container .project-card-content {
  text-align: left;
}

@media (max-width: 900px) {
  .project-list {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
  .service-card {
    padding: 10px !important;
  }
  .container {
    padding: 5px !important;
  }
}
@media (max-width: 400px) {
  .project-list {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  }
  .service-card {
    padding: 10px !important;
  }
  .container {
    padding: 5px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/projectlist.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,sBAAA;EACA,wBAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;AAER;AADQ;EACI,gBAAA;AAGZ;;AAEA;EACI;IACI,yDAAA;EACN;EACE;IACI,wBAAA;EACN;EACE;IACI,uBAAA;EACN;AACF;AACA;EACI;IACI,yDAAA;EACN;EACE;IACI,wBAAA;EACN;EACE;IACI,uBAAA;EACN;AACF","sourcesContent":[".project-list {\n    background-image: url(\"../../assets/images/service-bg.svg\");\n    background-size: cover;\n    background-position: top;\n    .container {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        text-align: center;\n        margin-top: 100px;\n        .project-card-content{\n            text-align: left;\n        }\n    };\n    \n}\n@media (max-width:900px) {\n    .project-list {\n        background-image: url(\"../../assets/images/service-bg-md.svg\");\n    }\n    .service-card{\n        padding:10px !important;\n    }    \n    .container {\n        padding: 5px !important;\n    }\n}\n@media (max-width:400px) {\n    .project-list {\n        background-image: url(\"../../assets/images/service-bg-sm.svg\");\n    }\n    .service-card{\n        padding:10px !important;\n    } \n    .container {\n        padding: 5px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
