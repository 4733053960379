import React from 'react';
const AboutCard = ({ number, description }) => {
    return (
        <div className="about-card">
            <div className="fs-h2 home-title color-gradi text-center">{number}</div>
            <div className="fs-18 color-white text-center">{description}</div>
        </div>
    );
};

export default AboutCard;