import React from 'react';
const Industry = () => {
    return <div className='industry-section' id='industry'>
        <div className="left-gradi"></div>
        <div className="right-gradi"></div>
        <div className='container mt-5'>
            <div className="d-flex">

                <div className='industry-detail'>
                    <div className='fs-h2 color-white'>Industries</div>
                    <div className='industry-content'>
                        <div className='industry-type color-white' style={{ width: 228, height: 228, rotate: '15deg', bottom: 0 }}>SOFTWARE AS A SERVICE (SAAS) PROVIDERS</div>
                        <div className='industry-type color-white' style={{ width: 185, height: 185, rotate: '-10deg', bottom: 0, left: 228 }}>FINTECH</div>
                        <div className='industry-type color-white' style={{ width: 190, height: 190, rotate: '-1deg', bottom: 0, left: 413 }}>E-COMMERCE</div>
                        <div className='industry-type color-white' style={{ width: 175, height: 175, rotate: '5deg', bottom: 172, left: 167 }}>EARLY STAGE STARTUPS</div>
                        <div className='industry-type color-white' style={{ width: 228, height: 228, rotate: '18deg', bottom: 182, left: 338 }}>CRYPTOCURRENCY & BLOCKCHAIN PROTOCOLS</div>
                    </div>

                </div>
                <div className='fs-b color-white lh-base ps-5 ms-5'>
                    Internally, we have strategically selected specific areas where we possess expertise and a track record of successful outcomes. <br></br><br></br>
                    Our focus spans across diverse industries that are at the forefront of innovation and economic growth. By collaborating with early-stage startups, we are nurturing groundbreaking ideas and helping them evolve into scalable solutions.
                </div>
            </div>
            <div style={{ background: '#FFFFFF05', height: 60, width: '100%', borderRadius: 12 }}></div>

        </div>
    </div>
}

export default Industry;